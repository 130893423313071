import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eaee5a84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prompt-tag-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PromptTag = _resolveComponent("PromptTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (text) => {
      return (_openBlock(), _createBlock(_component_PromptTag, {
        class: "prompt-tag",
        text: text
      }, null, 8, ["text"]))
    }), 256))
  ]))
}