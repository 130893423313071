
import {Options, Vue} from 'vue-class-component';
import {PropType} from "vue";
export type TagSize =  'normal' | 'large';
@Options<MetaTag>({
  components: {},
  props: {
    showCloseBtn:{
      required:false,
      type:Boolean,
      default:false
    },
    size:{
      required:false,
      type:String as PropType<TagSize>,
      default:'normal'
    }
  }
})
export default class MetaTag extends Vue {
  showCloseBtn!:boolean;
  size!:TagSize;
  handleCloseBtnClicked(){
    this.$emit('delete')
  }
}
