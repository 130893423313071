
import {Options, Vue} from 'vue-class-component';
import MetaTag, {TagSize} from "@/components/MetaTag.vue";
import {MetaLocales} from "@/types/Lang";
import {propertiesCn2LocaleKeyMap} from "@/types/settingPropertiesMap";
import {isChn} from "@/utils";
import {PropType} from "vue";

@Options<PromptTag>({
  components: {MetaTag},
  props: {
    text:{
      required:true,
      type:String,
    },
    showCloseBtn:{
      required:false,
      type:Boolean,
      default:false
    },
    size:{
      required:false,
      type:String as PropType<TagSize>,
      default:'normal'
    }
  }
})
export default class PromptTag extends Vue {
  text!:string;
  showCloseBtn!:boolean;
  size!:TagSize;
  MetaLocales = MetaLocales;
  propertiesCn2LocaleKeyMap = propertiesCn2LocaleKeyMap;
  isChn = isChn;

  handleDelete(){
    this.$emit('delete',this.text);
  }
}
