import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaTag = _resolveComponent("MetaTag")!

  return (_openBlock(), _createBlock(_component_MetaTag, {
    class: "prompt-tag",
    size: _ctx.size,
    showCloseBtn: _ctx.showCloseBtn,
    onDelete: _ctx.handleDelete
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.isChn(_ctx.text) ? ( _ctx.propertiesCn2LocaleKeyMap[_ctx.text] ? _ctx.$t(_ctx.propertiesCn2LocaleKeyMap[_ctx.text]) : _ctx.text) : _ctx.$t(_ctx.text)), 1)
    ]),
    _: 1
  }, 8, ["size", "showCloseBtn", "onDelete"]))
}