//decrepit
const propertiesEn2CnMap : {[k:string]:string} = {
    '3dRendering':'3D渲染',
    "hand-painted":"手绘",
    "watercolour":"水彩",
    "illustration":"插画",
    "sketch":"",
    "photorealistic":"照片",
    "black":"黑色背景",
    "white":"白色背景",
    "red":"红色背景",
    "blue":"蓝色背景",
    "gray":"灰色背景",
    "beige":"米色背景",
    "front":"前视图",
    "rear":"后方视图",
    "top":"顶视图",
    "side":"侧视图",
    "plain":"",
    "normal":"",
    "close-ups":"特写",
    "microscopic":"微距",
    "copySpace": "拷贝空间",
    "longExposure": "长时间曝光",
    "multipleExposures": "多次曝光"
};
//用来支持老数据
export const propertiesCn2LocaleKeyMap : {[k:string]:string} = {
    '3D渲染': '3dRendering',
    "手绘": "hand-painted",
    "水彩": "watercolour",
    "插画": "illustration",
    "照片": "photorealistic",
    "黑色背景": "black",
    "白色背景": "white",
    "红色背景": "red",
    "蓝色背景": "blue",
    "灰色背景": "gray",
    "米色背景": "beige",
    "前视图": "front",
    "后方视图": "rear",
    "顶视图": "top",
    "侧视图": "side",
    "特写": "close-ups",
    "微距": "microscopic",
    "拷贝空间": "copySpace",
    "长时间曝光": "longExposure",
    "多次曝光": "multipleExposures"
};
