
import {Options, Vue} from 'vue-class-component';
import HeaderLogo from "@/components/universalUI/HeaderLogo.vue";
import {colorLog} from "@/utils";
import RotateRing from "@/components/RotateRing.vue";
import CreationShareData from "@/types/api/CreationData";
import {getShare} from "@/api";
import UserAvatar from "@/components/UserAvatar.vue";
import {ShareDTO} from "@/types/api/Common";
import store from "@/store";
import {ViewNames} from "@/router";
import MetaTag from "@/components/MetaTag.vue";
import PromptTag from "@/components/PromptTag.vue";
import PromptTagGroup from "@/components/PromptTagGroup.vue";

@Options<Piece>({
  components: {PromptTag, MetaTag, UserAvatar, RotateRing, HeaderLogo, PromptTagGroup },
  props: {}
})
export default class Piece extends Vue {
  detail:CreationShareData | null = null;
  initialLoading = true;
  async created(){
    const shareKey = this.$route.query.shareKey as string;
    const res = await getShare(shareKey);
    const shareDTO:ShareDTO = res.data.data;
    this.detail = shareDTO.data;

    this.initialLoading = false;
  }

  handleTryBtnClicked(){
    this.$router.push({
      name:store.userInfo ? ViewNames.Home : ViewNames.Login,
      query:this.$route.query
    })
  }
}
