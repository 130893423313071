
import {Options, Vue} from 'vue-class-component';
import {PropType} from "vue";
import PromptTag from "@/components/PromptTag.vue";

@Options<PromptTagGroup>({
  components: {PromptTag},
  props: {
    texts:{
      type:Array as PropType<string[]>,
      required:true
    }
  }
})
export default class PromptTagGroup extends Vue {
  texts!:string[];
}
